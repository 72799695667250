<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ y: 350 }"
      row-key="id"
    />

    <a-form-item label="卡号总数" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ cardsCount }}</span>
    </a-form-item>

    <a-form-item label="重复数量" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ duplicateCardsCount }}</span>
    </a-form-item>
  </div>
</template>

<script>
import { findCancelCardManagesCarrierAccountsByCarrierType } from '@/api/cancel_card_manage'
export default {
  name: 'AllExpectCancelCard',
  props: {
    carrierType: {
      type: String,
      required: true
    },
    cardsCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      duplicateCardsCount: 0,
      loading: false
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: '待销卡数',
          dataIndex: 'expect_cancel_card_count',
          fixed: 'right'
        }
      ]
    },

    currentCardsCount: {
      get() {
        return this.cardsCount
      },
      set(val) {
        this.$emit('update:cardsCount', val)
      }
    }
  },
  created() {
    this.fetchCancelCardManagesCarrierAccountsByCarrierType()
  },
  methods: {
    fetchCancelCardManagesCarrierAccountsByCarrierType() {
      this.loading = true
      findCancelCardManagesCarrierAccountsByCarrierType(Object.assign({}, { carrier_type: this.carrierType })).then((res) => {
        if (res.code === 0) {
          this.data = res.data.carrier_accounts
          this.currentCardsCount = res.data.all_expect_cancel_card_count
        }
        this.loading = false
      })
    }
  }
}
</script>
